<template>
    <div class="col-12 mx-auto">
        <v-row class="justify-center white">
            <v-col cols="8" class="d-flex justify-center " style=" height:40vh; ">
                <div class="col-12 text-center align-self-end">
                    <h1 style="font-size: 3em;color:#333;" data-aos="slide-right">Nå ud til journalister med dine pressemeddelelser</h1>
                    <p data-aos="slide-left" style="color:#333;">Få den komplette løsning til, hvordan du engagerer tusindvis</p>
                    <br/>
                </div>
            </v-col>
        </v-row>
        <v-row class="justify-center white">
            <v-col cols="12" class="d-flex justify-center" style="height:40vh" data-aos="fade-up">
                <v-btn color="primary" x-large>Kontakt og hør mere</v-btn>
            </v-col>
        </v-row>
        <v-row class="d-flex justify-center primary" style="padding:250px 0 150px 0;min-height: 100vh">
            <v-col cols="4" data-aos="fade-right" data-aos-duration="1000">
                <h2 style="font-size: 3em">En overskrift med tekst</h2>
                <p>Sørme en hel masse text lige her. wow mere tekst</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur nisl mauris, ornare at vulputate et, vulputate a massa. Nullam quis lectus eu velit gravida cursus. Nulla faucibus condimentum magna quis tempor. Praesent elementum sodales orci. Aenean vel tempor arcu, sed varius ex. Fusce in tellus ac lectus hendrerit commodo. Nulla ut ultricies leo. Ut in est tortor. In diam purus, molestie vehicula egestas vitae, aliquet at dui.</p>
                <p>Proin vel metus sed dolor vulputate gravida a eu nunc. Praesent rutrum fringilla luctus. Pellentesque tempus neque eu pulvinar dictum. Suspendisse interdum ipsum eget libero sollicitudin iaculis. Mauris a felis tempus, egestas libero ut, pellentesque elit. In pharetra erat non leo lobortis tempor. Praesent ac bibendum odio, a convallis orci. Sed vitae feugiat arcu. Integer semper, nisi ac fermentum aliquam, erat metus feugiat tellus, id placerat dui enim finibus diam. Sed et
                    leo vitae sapien fermentum pretium. Quisque ut lacus quis nisi tincidunt consectetur quis id ante.</p>
            </v-col>
            <v-col cols="4" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="00">
                <img src="https://db.kompetencekanalen.dk/perspective-lg.png"/>
            </v-col>
        </v-row>
        <v-row class="d-flex justify-center secondary" style="padding:250px 0 150px 0;min-height: 100vh">
            <v-col cols="8">
                <v-row>
                    <v-col cols="3" data-aos="fade-up" data-aos-delay="100" data-aos-duration="500">
                        <v-toolbar elevation="0" class="text-center transparent">
                            <v-spacer/>
                            <v-icon size="128">mdi-human-greeting</v-icon>
                            <v-spacer/>
                        </v-toolbar>
                        <v-card>
                            <v-card-title>En titel</v-card-title>
                            <v-card-text>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur nisl mauris, ornare at vulputate et, vulputate a massa. Nullam quis lectus eu velit gravida cursus. Nulla faucibus condimentum magna quis tempor. Praesent elementum sodales orci. Aenean vel tempor arcu, sed varius ex. Fusce in tellus ac lectus hendrerit commodo. Nulla ut ultricies leo. Ut in est tortor. In diam purus, molestie vehicula egestas vitae, aliquet at dui.</v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="3" data-aos="fade-up" data-aos-delay="200" data-aos-duration="500">
                        <v-toolbar elevation="0" class="text-center transparent">
                            <v-spacer/>
                            <v-icon size="128">mdi-cogs</v-icon>
                            <v-spacer/>
                        </v-toolbar>
                        <v-card>
                            <v-card-title> En titel</v-card-title>
                            <v-card-text>Proin vel metus sed dolor vulputate gravida a eu nunc. Praesent rutrum fringilla luctus. Pellentesque tempus neque eu pulvinar dictum. Suspendisse interdum ipsum eget libero sollicitudin iaculis. Mauris a felis tempus, egestas libero ut, pellentesque elit. In pharetra erat non leo lobortis tempor. Praesent ac bibendum odio, a convallis orci. Sed vitae feugiat arcu. Integer semper, nisi ac fermentum aliquam, erat metus feugiat tellus, id placerat dui enim
                                finibus
                                diam. Sed et leo vitae sapien fermentum pretium. Quisque ut lacus quis nisi tincidunt consectetur quis id ante.
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="3" data-aos="fade-up" data-aos-delay="300" data-aos-duration="500">
                        <v-toolbar elevation="0" class="text-center transparent">
                            <v-spacer/>
                            <v-icon size="128">mdi-poker-chip</v-icon>
                            <v-spacer/>
                        </v-toolbar>
                        <v-card>
                            <v-card-title>En titel</v-card-title>
                            <v-card-text>Ut egestas, lorem ac venenatis rutrum, velit nisl cursus sapien, sit amet interdum arcu mauris ac nisl. Suspendisse potenti. Quisque dui metus, ornare sit amet faucibus id, ornare sed nibh. Fusce pellentesque, lacus at finibus tempor, nisl sapien aliquam lectus, in venenatis erat turpis sed lacus. Phasellus efficitur mollis hendrerit. In a aliquam ex. Nullam quis vehicula augue.</v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="3" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
                        <v-toolbar elevation="0" class="text-center transparent">
                            <v-spacer/>
                            <v-icon size="128">mdi-polymer</v-icon>
                            <v-spacer/>
                        </v-toolbar>
                        <v-card>
                            <v-card-title>En titel</v-card-title>
                            <v-card-text>Maecenas dignissim facilisis metus a pretium. Pellentesque tincidunt elit quam, a egestas justo maximus eget. Aliquam volutpat ac erat eu lobortis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam molestie ullamcorper augue non rhoncus. Donec mauris ipsum, maximus a purus nec, finibus venenatis mi. Sed eu auctor turpis. Nullam bibendum sagittis arcu nec maximus. In id turpis risus. Duis eget tortor
                                massa. Aenean
                                ac interdum nunc.
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="d-flex justify-center secondary darken-1" style="padding:50px 0 150px 0;">
            <v-col cols="8" class="pa-0">
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="4">
                                <p class="headline">header</p>
                                <ul>
                                    <li>asldom</li>
                                    <li>asldom</li>
                                    <li>asldom</li>
                                    <li>asldom</li>
                                </ul>
                            </v-col>
                            <v-col cols="4">
                                <p class="headline">Privatliv</p>
                                <router-link :to="{path:'/cookies'}">Cookies</router-link>
                            </v-col>
                            <v-col cols="4">
                                <p class="headline">header</p>
                                <v-text-field solo-inverted label="Nyhedsbrev"/>
                                <v-btn outlined width="100%">Tilmeld</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="secondary pa-0 darken-1">
            <v-col class="text-center ">&copy; copyright 2021</v-col>
        </v-row>
    </div>
</template>
<script>


    import LoginDialog from "../../components/user/loginDialog";
    import Login from "../Login";
    export default {
        name: 'frontPage',
        components: {Login, LoginDialog},
        data() {
            return {}
        },
        methods: {},
        computed: {},
        mounted() {
        }
    }
</script>
